import { FilterableOption, FilterDataRangeValue, FilterDataValue } from '../../utils/filters';
import Input from './Input';
import React from 'react';

export interface MinMaxValueProps {
    value: FilterDataValue;
    filterable: FilterableOption;
    // onChange: (value: FilterDataValue) => void,
    onChange: Function;
}

export const MinMaxValue = ({ filterable, value, onChange }: MinMaxValueProps) => {
    const valueRange = value as FilterDataRangeValue;
    return (
        <div className='flex justify-start space-x-2'>
            <Input
                id={filterable.field + '_filter_input_max'}
                value={valueRange.min}
                type={filterable.type}
                step={filterable.step || 1}
                min={filterable.min || 0}
                max={filterable.max}
                onChange={(e) =>
                    onChange(
                        Object.assign(
                            {},
                            { min: e.target.value, max: valueRange.max,  }
                        )
                    )
                }
            />
            <Input
                id={filterable.field + '_filter_input_min'}
                value={valueRange.max}
                type={filterable.type}
                step={filterable.step || 1}
                min={filterable.min || 0}
                max={filterable.max}
                onChange={(e) =>
                    onChange(
                        Object.assign(
                            {},
                            { min: valueRange.min , max: e.target.value, }
                        )
                    )
                }
            />
        </div>
    );
};
