import axios from 'axios';

export const apiClient = axios.create({
    baseURL: import.meta.env.VITE_BASEURL + '/api',
    withCredentials: true,
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json',
        },
    },
});

export const baseClient = axios.create({
    baseURL: import.meta.env.VITE_BASEURL,
    withCredentials: true,
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    },
});
