import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import Input from './Input';
import { FilterDataRangeValue } from '../../utils/filters';

export interface DateControlProps {
    isRange: boolean;
    type: string;
    value: string | FilterDataRangeValue;
    onChange: Function;
    id: string;
}

const DateControl: FunctionComponent<DateControlProps> = (props) => {
    const { isRange, type, id, value, onChange } = props;
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');

    const handleInputChange = useCallback(() => {
        onChange &&
            onChange({
                min: minDate,
                max: maxDate,
            });
    }, [minDate, maxDate]);

    useEffect(() => {
        if (isRange && value && value?.min !== '' && value?.max !== '') {
            setMinDate(value?.min ?? '');
            setMaxDate(value?.max ?? '');
        }
    }, [value]);

    useEffect(() => {
        if (isRange && value?.min !== minDate && value?.max !== maxDate) return;
        handleInputChange();
    }, [minDate, maxDate]);

    const handleSingleValueChange = (e) => {
        onChange && onChange(e.target.value);
    };

    const handleMinValueChange = (e) => {
        if (typeof value !== 'string') {
            setMinDate(e.target.value);
        }
    };

    const handleMaxValueChange = (e) => {
        if (typeof value !== 'string') {
            setMaxDate(e.target.value);
        }
    };

    return !isRange ? (
        <div>
            <Input
                className="w-40"
                id={id}
                value={value}
                type={type}
                onChange={handleSingleValueChange}
            />
        </div>
    ) : (
        <div className="flex flex-col space-y-2 border-l-4 border-solid border-gray-400">
            <div className="flex items-center space-x-1">
                <div className="grid grid-cols-1 grid-rows-2 gap-2 space-x-0 md:grid-cols-1 md:grid-rows-1 ">
                    <div className="flex flex-col items-start space-y-1 rounded-sm pl-3">
                        <label
                            className="flex flex-1 flex-col text-center text-xs uppercase text-gray-600"
                            htmlFor={id + '_date_min'}
                        >
                            Desde
                        </label>
                        <div className="flex space-x-1">
                            <Input
                                id={id + '_date_min'}
                                value={
                                    typeof value !== 'string' ? value?.min : ''
                                }
                                type={type}
                                onChange={handleMinValueChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-start space-y-1 rounded-sm pl-3">
                <label
                    className="flex-1 text-center text-xs uppercase text-gray-600"
                    htmlFor={id + '_date_max'}
                >
                    Hasta
                </label>
                <div className="flex space-x-1">
                    <Input
                        id={id + '_date_max'}
                        value={typeof value !== 'string' ? value?.max : ''}
                        type={type}
                        onChange={handleMaxValueChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateControl;
