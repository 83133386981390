interface MecFocProps {
    label: String,
    value: Number,
    marginClass?: String,
}

export default function MecFocal(props: MecFocProps): JSX.Element {
    return (
        <div className="flex flex-col">
            <span className="text-[.6rem] uppercase text-gray-600">{props.label}</span>
            <span className={`text-[.90rem] text-gray-600 ${props.marginClass ? props.marginClass : '-mt-1'}`}>{props.value}&deg;</span>
        </div>
    );
}
