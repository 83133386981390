export type FilterableOption = {
    field: string;
    label: string;
    type: string;
    operations: string[];
    step?: number;
    min?: number;
    max?: number;
    defaultValue?: string;
    options?: { [key: string]: string };
};

export type FilterDataRangeValue = { min: string; max: string };
export type FilterCoordsRange = string[];
export type FilterDataValue = string | FilterDataRangeValue | FilterCoordsRange;

export interface FilterDataMap {
    [key: string]: FilterData;
}

export interface FilterData {
    field?: string;
    operator: string;
    value: FilterDataValue;
    editedField?: string;
    order?: number;
}

export const generalFilters = [
    'page',
    'search',
    'limit',
    'orderBy',
    'orderDirection',
];

export const getDefaultValueForOperator = (operator) => {
    switch (operator) {
        case 'interval':
            return { min: '', max: '' };
        case 'coordsRange':
            return [20, 17.5, -75, -65];
        default:
            return '';
    }
};

export const getSetParamVariants = (urlParams, field) => {
    const paramVariants = {};
    const gt = field + '>';
    const lt = field + '<';

    if (urlParams.get(gt)) {
        paramVariants[gt] = urlParams.get(gt);
    }
    if (urlParams.get(lt)) {
        paramVariants[lt] = urlParams.get(lt);
    }
    if (urlParams.get(field)) {
        paramVariants[field] = urlParams.get(field);
    }
    return paramVariants;
};

export function getFiltersFromQueryString(
    urlParams: URLSearchParams,
    fieldNames: string[]
): FilterDataMap {
    const map = {};
    fieldNames.forEach((fieldName) => {
        const paramVariants = getSetParamVariants(urlParams, fieldName);
        if (
            paramVariants[fieldName + Operators.GTE] &&
            paramVariants[fieldName + Operators.LTE]
        ) {
            map[fieldName] = {
                field: fieldName,
                operator: Operators.INTERVAL,
                value: {
                    min: paramVariants[fieldName + Operators.GTE],
                    max: paramVariants[fieldName + Operators.LTE],
                },
            };
        } else if (paramVariants[fieldName]) {
            map[fieldName] = {
                field: fieldName,
                operator: Operators.EQ,
                value: paramVariants[fieldName],
            };
        } else if (paramVariants[fieldName + Operators.LTE]) {
            map[fieldName] = {
                field: fieldName,
                operator: Operators.LTE,
                value: paramVariants[fieldName + Operators.LTE],
            };
        } else if (paramVariants[fieldName + Operators.GTE]) {
            map[fieldName] = {
                field: fieldName,
                operator: Operators.GTE,
                value: paramVariants[fieldName + Operators.GTE],
            };
        }
    });
    return map;
}

export const translations = {
    interval: 'Intérvalo',
    coordsRange: 'Rango de coordenadas',
    '>': 'Mayor o igual a',
    '<': 'Menor o igual a',
    '=': 'Igual a',
    automatic: 'Automático',
    provisional: 'Provisional',
    revised: 'Revisado',
};

export const Operators = {
    INTERVAL: 'interval',
    COORDS_RANGE: 'coordsRange',
    LTE: '<',
    GTE: '>',
    EQ: '=',
};
