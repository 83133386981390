import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon, TrashIcon } from '@heroicons/react/outline';

const DeleteQuake = ({ quake, onConfirm }) => {
    const [open, setOpen] = useState(false);

    const handleSetOpen = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    };

    const handleRemoveConfirmation = () => {
        onConfirm && onConfirm(quake.id);
    };

    return (
        <>
            <button
                className="space-x-1 rounded-full p-2 text-brand-blue opacity-80 hover:bg-gray-100 hover:opacity-100 active:bg-brand-blue active:text-gray-100"
                title={'Eliminar Sismo ID=' + quake.id}
                aria-label={'Eliminar Sismo with ID equal to ' + quake.id}
                onClick={handleSetOpen}
            >
                <TrashIcon className="h-4 w-4 " />
            </button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">
                                                Cerrar
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title
                                                as="div"
                                                className="flex space-x-1"
                                            >
                                                <ExclamationIcon
                                                    className="h-6 w-6 text-red-600"
                                                    aria-hidden="true"
                                                />
                                                <h3 className="text-lg font-medium leading-6 text-gray-900 ">
                                                    Eliminar Sismo
                                                </h3>
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Estás seguro de que deseas
                                                    eliminar este evento
                                                    sísmico?
                                                </p>
                                                <div className="my-3 grid grid-cols-2 gap-3 rounded-md bg-gray-100 p-4">
                                                    <div>
                                                        <div className="text-xs uppercase text-gray-500">
                                                            ID
                                                        </div>
                                                        <div className="text-lg text-gray-700">
                                                            {quake.id}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="text-xs uppercase text-gray-500">
                                                            Magnitud
                                                        </div>
                                                        <div className="text-lg text-gray-700">
                                                            {quake.magnitude}
                                                            <sub className="text-xs">
                                                                {quake.unit}
                                                            </sub>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="text-xs uppercase text-gray-500">
                                                            RMS
                                                        </div>
                                                        <div className="text-lg text-gray-700">
                                                            {
                                                                quake.rms
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="text-xs uppercase text-gray-500">
                                                            Profundidad
                                                        </div>
                                                        <div className="text-lg text-gray-700">
                                                            {quake.depth}{' '}
                                                            <sub className="text-xs">
                                                                Km(s)
                                                            </sub>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="text-xs uppercase text-gray-500">
                                                            Coordenadas
                                                        </div>
                                                        <div className="text-lg text-gray-700">
                                                            {quake.latitude}
                                                            &deg;,{' '}
                                                            {quake.longitude}
                                                            &deg;
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mt-2 text-sm text-gray-500">
                                                    Si hace click en
                                                    <em className="italic text-red-600">
                                                        {' '}
                                                        Sí, estoy seguro
                                                    </em>
                                                    , el mismo se eliminará y no
                                                    podrá ser recuperado.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={handleRemoveConfirmation}
                                        >
                                            Sí, estoy seguro
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default DeleteQuake;
