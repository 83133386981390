export default function MediumHeader({ title, children }) {
    return (
        <div className='py-5'>
            <div className='mt-3 flex flex-col xl:flex-row xl:items-center xl:justify-between space-y-2 xl:space-y-0'>
                {title &&
                <div className='min-w-0 flex-1'>
                    <h2 className='text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl lg:text-4xl'>
                        {title}
                    </h2>
                </div>
                }
                <div className='mt-4 flex flex-shrink-0 md:mt-0 space-x-3'>
                    {children}
                </div>
            </div>
        </div>
    );
}
