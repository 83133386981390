import { UnitOption, UnitOptionMap } from './types';

export const unitOptions: UnitOption[] = [
    {
        label: 'Escala Local',
        value: 'Ml'
    },
    {
        label: 'Escala de Momento',
        value: 'Mw'
    },
    {
        label: 'Escala de Coda',
        value: 'Mc'
    }
];

export const unitOptionMap: UnitOptionMap = {
    'Ml': unitOptions[0].label,
    'Mw': unitOptions[1].label,
    'Mc': unitOptions[2].label,
}
