import React, { useEffect, useState } from 'react';
import {
    FilterableOption,
    FilterData,
    FilterDataValue,
    getDefaultValueForOperator,
    translations,
} from '../../utils/filters';
import { TrashIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';
import { quakeFilterableOptions } from '../../modules/quakes/utils/filter';
import { FormField } from './FormField';

export interface FilterAddProps {
    onClose: Function;
    onApply: Function;
}

export const FilterAdd = (props: FilterAddProps) => {
    const { onClose, onApply } = props;
    const [selectedField, setSelectedField] = useState('');
    const [selectedOperator, setSelectedOperator] = useState('');
    const [changedValue, setChangedValue] = useState<FilterDataValue>('');
    const [filterOptions, setFilterOptions] = useState<FilterableOption>([]);

    const handleClose = () => {
        onClose && onClose();
    };

    const handleOperatorChange = (e) => {
        const newOp = e.target.value;
        setSelectedOperator(newOp);
        setChangedValue(getDefaultValueForOperator(newOp));
    };

    const handleValueChange = (newValue: FilterDataValue) => {
        setChangedValue(newValue);
    };

    const handleFieldChange = (e) => {
        const f = e.target.value;
        setSelectedField(f);
    };

    const handleApply = () => {
        const fd: FilterData = {
            value: changedValue,
            operator: selectedOperator,
        };
        onApply && onApply({ ...fd, field: selectedField });
    };

    useEffect(() => {
        if (selectedField) {
            setSelectedOperator('');
        }
    }, [selectedField]);

    useEffect(() => {
        setFilterOptions(
            quakeFilterableOptions.find((qfo) => qfo.field === selectedField)
        );
    }, [selectedField]);

    return (
        <div className="FormFilter flex flex-col space-y-4 text-gray-600">
            <div className="flex flex-col space-y-3 xl:space-y-4">
                <div className="flex w-48 flex-col space-y-2">
                    <label
                        className="text-xs uppercase text-gray-600"
                        htmlFor="field"
                    >
                        Parámetro
                    </label>
                    <select
                        className="rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={selectedField}
                        onChange={handleFieldChange}
                    >
                        <option value="" disabled={true}>
                            Seleccione operador
                        </option>
                        {quakeFilterableOptions.map((qfo) => (
                            <option key={qfo.field} value={qfo.field}>
                                {qfo.label}
                            </option>
                        ))}
                    </select>
                </div>

                {filterOptions && (
                    <>
                        <div className="flex w-48 flex-col justify-start space-y-1">
                            <label
                                className="text-xs uppercase text-gray-600"
                                htmlFor="operator"
                            >
                                Operador:
                            </label>
                            {filterOptions.operations?.length >= 1 && (
                                <select
                                    className="rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    id="operator"
                                    name="operator"
                                    value={selectedOperator}
                                    onChange={handleOperatorChange}
                                >
                                    <option value="" disabled={true}>
                                        Seleccione operador
                                    </option>
                                    {(filterOptions.operations || []).map(
                                        (op) => (
                                            <option key={op} value={op}>
                                                {translations[op] || op}
                                            </option>
                                        )
                                    )}
                                </select>
                            )}
                        </div>

                        <div className="flex w-48 flex-col justify-start space-y-1">
                            {selectedOperator !== 'coordsRange' && (
                                <label
                                    className="text-xs uppercase text-gray-600"
                                    htmlFor="value"
                                >
                                    Valor:
                                </label>
                            )}
                            {selectedOperator && (
                                <FormField
                                    value={changedValue}
                                    onChange={handleValueChange}
                                    options={filterOptions}
                                    operator={selectedOperator}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
            <hr className="border-1 border-top border-gray-300" />
            <div className="mt-6 flex space-x-2">
                <button
                    className="flex items-center space-x-1 text-xs capitalize"
                    type="button"
                    onClick={handleClose}
                >
                    <XIcon className="h-4 w-4" />
                    <span>Cerrar Editor</span>
                </button>
                <button
                    className="inline-flex justify-center space-x-1 rounded-md border border-transparent bg-indigo-600 py-1 px-2 text-xs font-medium capitalize text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    type="button"
                    onClick={handleApply}
                >
                    <TrashIcon className="h-4 w-4" />
                    <span>Aplicar Filtro</span>
                </button>
            </div>
        </div>
    );
};

export default FilterAdd;
