import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import QuakeTable from '../components/QuakeTable';
import MediumHeader from '../../../components/MediumHeader';
import SlideOverly from '../../../components/SlideOverly';
import QuakeMap from '../components/QuakeMap';
import { fetchQuakes, deleteQuake } from '../QuakeService';
import { quakesAtom } from '../../../state/atoms';
import QuakeForm from '../components/QuakeForm';
import QuakeToolBar from '../components/QuakeToolBar';
import qs from 'qs';

const QuakeList = ({ isAdmin = false, withTitle = true }) => {
    const [quakeFormOpen, setQuakeFormOpen] = useState(false);
    const [quakesRes, setQuakesRes] = useRecoilState(quakesAtom);
    const [selectedQuake, setSelectedQuake] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const initialSearch = (new URLSearchParams(window.location.search)).get('search');
    const [isFiltering, setIsFiltering] = useState(false);

    const defaultMapProps = {
        center: {
            lat: 18.78,
            lng: -70.2
        },
        zoom: 7
    };
    const [mapProps, setMapProps] = useState(defaultMapProps);

    useEffect(() => {
        load();
    }, [searchParams]);

    useEffect(() => {
        if (selectedQuake) {
            console.log('A quake was selected. Updating map...')
            setMapProps({
                zoom: 8,
                center: {
                    lat: selectedQuake.latitude,
                    lng: selectedQuake.longitude
                }
            });
        } else {
            setMapProps({ ...defaultMapProps });
        }
    }, [selectedQuake]);

    const load = async () => {
        try {
            const qString = searchParams.toString();
            const res = await fetchQuakes(qString);
            setQuakesRes(res);
        } catch (err) {
            throw err;
        }
    };

    const editQuakeHandler = (quake) => {
        setQuakeFormOpen(true);
        selectQuakeHandler(quake);
    };

    const addQuakeHandler = () => {
        resetSelectionHandler();
        setQuakeFormOpen(true);
    };

    const selectQuakeHandler = (quake) => {
        if (quake) {
            setSelectedQuake(quake);
        } else {
            setSelectedQuake(null);
        }
    };

    const resetSelectionHandler = () => {
        setQuakeFormOpen(false);
        setMapProps(defaultMapProps);
        setSelectedQuake(null);
    };

    const onCloseQuakeFormModalHandler = (withReload = false) => {
        setQuakeFormOpen(false);
        withReload && load();
    };

    const handleSort = ({ by, direction }) => {
        const filters = qs.parse(searchParams.toString());
        const qString = qs.stringify({
            ...filters,
            orderBy: by,
            orderDirection: direction
        });
        setSearchParams(qString);
    };

    const quakeDeleteHandler = async (quakeId) => {
        try {
            await deleteQuake(quakeId);
            await load();
        } catch (err) {
            throw err;
        }
    };

    return (
        <>
            <div className='mx-auto max-w-8xl px-4 sm:px-6 md:px-8 divide-y divide-gray-200 '>
                <div>
                    <MediumHeader title={withTitle && 'Eventos Sísmicos'}>
                        <>
                            {isAdmin &&
                                <>
                                    {selectedQuake && (
                                        <button
                                            type='button'
                                            onClick={resetSelectionHandler}
                                            className='inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                        >
                                            Eliminar Selección
                                        </button>
                                    )}

                                    <button
                                        onClick={addQuakeHandler}
                                        type='button'
                                        className='inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                    >
                                        Registrar Sismo
                                    </button>
                                </>
                            }
                        </>
                    </MediumHeader>
                    <QuakeToolBar
                        meta={quakesRes.meta}
                        links={quakesRes.links}
                        search={initialSearch}
                        reset={
                            !isAdmin && selectedQuake && (
                                <button
                                    type='button'
                                    onClick={resetSelectionHandler}
                                    className='inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                >
                                    Eliminar Selección
                                </button>
                            )
                        }
                    />
                </div>
                <div className='py-5 mt-5 flex flex-1 flex-col'>
                    {!isFiltering &&
                        <div className='min-h-96 h-96 '>
                            <QuakeMap
                                items={quakesRes.data || []}
                                {...mapProps}
                                defaultProps={defaultMapProps}
                                selectedItem={selectedQuake}
                                onSelectedItem={selectQuakeHandler}
                            />
                        </div>
                    }
                    <div className='mt-5 w-full space-y-5'>
                        <QuakeTable
                            quakes={quakesRes.data}
                            editQuake={editQuakeHandler}
                            onSelect={selectQuakeHandler}
                            selectedQuake={selectedQuake}
                            onSort={handleSort}
                            orderBy={searchParams.get('orderBy')}
                            orderDirection={searchParams.get('orderDirection')}
                            onDeleteQuake={quakeDeleteHandler}
                        />
                    </div>
                </div>
                {isAdmin && (
                    <SlideOverly
                        title={selectedQuake ? 'Editar Sismo' : 'Registrar Sismo'}
                        open={quakeFormOpen}
                        setOpen={setQuakeFormOpen}
                        headerActions={
                            <Link
                                to='/quakes/add-new'
                                alt='Abrir como página'
                                className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            >
                                <ExternalLinkIcon className='h-6 w-6' />
                            </Link>
                        }
                    >
                        <QuakeForm
                            quake={selectedQuake}
                            onClose={onCloseQuakeFormModalHandler}
                        />
                    </SlideOverly>
                )}
            </div>
        </>
    );
};

export default QuakeList;
