import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import { FilterCoordsRange, FilterDataRangeValue } from '../../utils/filters';

interface CoordinatesRangeEditProps {
    value: FilterCoordsRange;
    onChange: (value: FilterCoordsRange) => void;
    step: number;
}

const CoordinatesRangeEdit = (props: CoordinatesRangeEditProps) => {
    const { value, onChange, step } = props;
    const [latNorth, setLatNorth] = useState('');
    const [latSouth, setLatSouth] = useState('');
    const [lngWest, setLngWest] = useState('');
    const [lngEast, setLngEast] = useState('');

    const handleInputChange = useCallback(() => {
        triggerOnChange();
    }, [latNorth, lngWest, latSouth, lngEast]);

    useEffect(() => {
        if (
            Array.isArray(value) &&
            value.filter((f) => f === '').length === 4
        ) {
            return;
        }
        const values = value;
        setLatNorth(values[0]);
        setLatSouth(values[1]);
        setLngWest(values[2]);
        setLngEast(values[3]);
    }, [value]);

    useEffect(() => {
        handleInputChange();
    }, [latSouth, latNorth, lngEast, lngWest]);

    const triggerOnChange = () => {
        onChange([latNorth, latSouth, lngWest, lngEast]);
    };

    const handleLatNorthChange = (e) => {
        setLatNorth(e.target.value);
    };

    const handleLngWestChange = (e) => {
        setLngWest(e.target.value);
    };

    const handleLatSouthChange = (e) => {
        setLatSouth(e.target.value);
    };

    const handleLngEastChange = (e) => {
        setLngEast(e.target.value);
    };

    return (
        value && (
            <div className="grid grid-cols-1 grid-rows-2 gap-3 space-x-0 border-l-4 border-solid border-gray-400 md:grid-cols-1 md:grid-rows-1">
                <div className="flex flex-col items-start space-y-1 rounded-sm pl-3">
                    <label
                        className="flex flex-1 flex-col text-center text-xs uppercase text-gray-600"
                        htmlFor={'coordinates_filter_input_min_lat'}
                    >
                        Latitudes (Norte y Sur):
                    </label>
                    <div className="flex space-x-1">
                        <Input
                            id={'coordinates_filter_input_north_lat'}
                            value={latNorth}
                            type="number"
                            step={step}
                            min="-90.000"
                            max="90.000"
                            onChange={handleLatNorthChange}
                            className="w-32 rounded-none"
                            placeholder="Norte"
                        />
                        <Input
                            id={'coordinates_filter_input_south_lat'}
                            value={latSouth}
                            type="number"
                            step={step}
                            min="-180.000"
                            max="180.000"
                            onChange={handleLatSouthChange}
                            className="w-32 rounded-none"
                            placeholder="Sur"
                        />
                    </div>
                </div>
                <div className="flex flex-col items-start space-y-1 rounded-sm pl-3">
                    <label
                        className="flex-1 text-center text-xs uppercase text-gray-600"
                        htmlFor={'coordinates_filter_input_max_lat'}
                    >
                        Longitudes (Oeste y Este):
                    </label>
                    <div className="flex space-x-1">
                        <Input
                            id={'coordinates_filter_input_max_lng'}
                            value={lngWest}
                            type="number"
                            step={step}
                            min="-180.000"
                            max="180.000"
                            onChange={handleLngWestChange}
                            className="w-32 rounded-none"
                            placeholder="Este"
                        />
                        <Input
                            id={'coordinates_filter_input_max_lat'}
                            value={lngEast}
                            type="number"
                            step={step}
                            min="-90.000"
                            max="90.000"
                            onChange={handleLngEastChange}
                            className="w-32 rounded-none"
                            placeholder="Oeste"
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default CoordinatesRangeEdit;
