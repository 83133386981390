import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { DownloadIcon } from '@heroicons/react/solid';

export default function CsvDownloadModal({ onDownloadRequest }) {
    const [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <button
                className='flex space-x-1 text-sm text-brand-blue group items-center hover:cursor-pointer'
                type='button' onClick={() => setIsOpen(true)}
                title="Descargar CSV"
            >
                <DownloadIcon className='group-hover:opacity-50 w-4 h-4' />
                <span className='hidden sm:inline'>Descargar CSV</span>
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-black bg-opacity-25' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel
                                    className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                                    <Dialog.Title
                                        as='h3'
                                        className='text-lg font-medium leading-6 text-brand-brown'
                                    >Descarga de Eventos Sísmicos</Dialog.Title>
                                    <div className='mt-2'>
                                        <p className='text-sm text-gray-500 mb-2'>
                                            Puedes descargar un límite de 20 eventos sísmicos a la vez usando este
                                            botón.
                                        </p>
                                        <p className='text-sm text-gray-500'>
                                            Contáctanos si necesitas más datos.
                                        </p>
                                    </div>

                                    <div className='mt-4 space-x-4'>
                                        <a
                                            className='text-brand-green'
                                            href={import.meta.env.VITE_SITEURL + '/contact-us'}
                                            onClick={closeModal}
                                        >Contactar</a>
                                        <button
                                            type='button'
                                            onClick={onDownloadRequest}
                                            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                                        >Descargar los 20
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
