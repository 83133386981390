import React, { FC, useEffect } from 'react';
import { formatCoordinates } from '../../../shared/format';
import {
    FilterDataMap,
    FilterableOption,
    FilterDataValue,
    Operators,
    translations,
} from '../../utils/filters';

interface FilterDetailsProps {
    filters: FilterDataMap;
    filterableOptions: FilterableOption[];
    onEditClicked: (field: string) => void;
    editingField: string;
}

interface FilterItemProps {
    value: FilterDataValue;
    operator: string;
    options: FilterableOption;
    onEditClicked: (field: string) => void;
    isEditing: boolean;
}

export const FilterItem: FC<FilterItemProps> = (props) => {
    const { value, operator, options, onEditClicked, isEditing } = props;

    const handleClick = () => {
        onEditClicked(options.field);
    };

    return (
        <div
            onClick={handleClick}
            className={`${
                isEditing
                    ? 'bg-gray-500 text-gray-100'
                    : 'bg-slate-100 text-gray-600'
            } flex cursor-pointer items-center rounded-md py-1 px-2 text-xs`}
        >
            <span>{options.label}</span>
            {operator === Operators.COORDS_RANGE && (
                <div className="flex space-x-0.5">
                    <span>:</span>
                    <span className="text-gray-400">[</span>
                    <span className="font-bold">
                        {formatCoordinates(value[0], value[1])}
                    </span>
                    <span className="text-gray-400">, </span>
                    <span className="font-bold">
                        {formatCoordinates(value[2], value[3])}
                    </span>
                    <span className="text-gray-400">]</span>
                </div>
            )}

            {operator === Operators.INTERVAL && (
                <div className="ml-2">
                    <span className="text-gray-400">[</span>
                    <span className="font-bold">{value.min}</span>
                    <span className="text-gray-400"> - </span>
                    <span className="font-bold">{value.max}</span>
                    <span className="text-gray-400">]</span>
                </div>
            )}

            {operator !== Operators.COORDS_RANGE &&
                operator !== Operators.INTERVAL && (
                    <>
                        <span className="px-1 text-green-400">{operator}</span>
                        <span className="font-bold">
                            {translations[value] || value}
                        </span>
                    </>
                )}
        </div>
    );
};

export const FilterDetails: FC<FilterDetailsProps> = ({
    editingField,
    filters,
    filterableOptions,
    onEditClicked,
}) => {
    return (
        <div className="FilterDetails flex flex-col space-y-4 text-gray-600">
            <div className="flex justify-start">
                <div className="flex space-x-2 rounded-md bg-slate-300 p-2">
                    {Object.keys(filters).map((fKey) => (
                        <FilterItem
                            onEditClicked={onEditClicked}
                            key={fKey}
                            value={filters[fKey].value}
                            operator={filters[fKey].operator}
                            options={filterableOptions.find(
                                (o) => o.field === fKey
                            )}
                            isEditing={fKey === editingField}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
