import OrderDirectionIcon from './OrderDirectionIcon';

const TableTh = ({
    title,
    supTitle,
    name,
    onSort,
    children,
    className,
    sorting,
}) => {
    return (
        <th
            onClick={() => onSort && onSort(name)}
            scope="col"
            className={
                (className || '') +
                (sorting ? ' cursor-pointer' : '') +
                ' px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'
            }
        >
            {children ? (
                children
            ) : (
                <span className="flex items-center">
                    <div className="truncate">
                        {title}
                        {supTitle && <sup>({supTitle})</sup>}
                    </div>
                    {sorting && (
                        <OrderDirectionIcon field={name} {...sorting} />
                    )}
                </span>
            )}
        </th>
    );
};

export default TableTh;
