import {
    FilterableOption,
    generalFilters,
    getSetParamVariants,
} from '../../../utils/filters';
import { quakeStatusLabelMap } from './enum';
import qs from 'qs';

export interface QuakeQueryStringFilters {
    magnitude?: string;
    unit?: string;
    rms?: string;
    depth?: string;
    coordinates?: string;
    status?: string;
    datetime?: string;
}

/** @deprecated **/
export const getQuakesFilters = (urlParams, except = []) => {
    let paramsMap = {};
    quakeFilterNames.forEach((fieldName) => {
        if (except.indexOf(fieldName) != -1) return;
        paramsMap = {
            ...paramsMap,
            ...getSetParamVariants(urlParams, fieldName),
        };
    });
    return paramsMap;
};

export function getQsFilters(searchStrings) {
    return qs.parse(searchStrings);
}

export function setQsFilter(filterData, searchString) {
    const paramsPairs = {};
    if (filterData.operator === 'interval') {
        paramsPairs[filterData.field] = {
            gte: filterData.value.min,
            lte: filterData.value.max,
        };
    } else if (filterData.operator === 'coordsRange') {
        paramsPairs[filterData.field] = filterData.value.join('|');
    } else if (filterData.operator === '>') {
        paramsPairs[filterData.field] = { gte: filterData.value };
    } else if (filterData.operator === '<') {
        paramsPairs[filterData.field] = { lte: filterData.value };
    } else {
        paramsPairs[filterData.field] = filterData.value;
    }

    return qs.stringify({
        ...getQsFilters(searchString),
        ...paramsPairs,
    });
}

export function removeQsFilter(field, searchStrings) {
    const paramsMap = qs.parse(searchStrings);
    const newParams = {};
    Object.keys(paramsMap).forEach((p) => {
        if (p === field) return;
        newParams[p] = paramsMap[p];
    });
    return qs.stringify(newParams);
}

export function getObjectValidFilters(searchString) {
    const obj = qs.parse(searchString);

    const filters = {};
    quakeFieldNames.forEach((fieldName) => {
        if (fieldName in obj) {
            const { gte, lte, eq } = obj[fieldName];
            const str: string = eq || obj[fieldName];
            if (typeof str === 'string') {
                if (str.includes('|')) {
                    filters[fieldName] = {
                        operator: 'coordsRange',
                        value: str.split('|'),
                    };
                } else {
                    filters[fieldName] = {
                        operator: '=',
                        value: str,
                    };
                }
            } else if (isSet(gte) && isSet(lte)) {
                filters[fieldName] = {
                    operator: 'interval',
                    value: {
                        min: gte,
                        max: lte,
                    },
                };
            } else if (isSet(gte)) {
                filters[fieldName] = {
                    operator: '>',
                    value: gte,
                };
            } else if (isSet(lte)) {
                filters[fieldName] = {
                    operator: '<',
                    value: lte,
                };
            }
        }
    });

    return filters;
}

export function isSet(value: string) {
    return value || value === '';
}

export const quakeFieldNames = [
    'magnitude',
    'unit',
    'rms',
    'depth',
    'coordinates',
    'status',
    'datetime',
];

export const QuakeFiledNameLabels = {
    magnitude: 'Magnitud',
    unit: 'Unidad',
    rms: 'RMS',
    depth: 'Profundidad',
    coordinates: 'Coordenadas',
    status: 'Estado',
    datetime: 'Fecha',
};

export const quakeFilterNames = [...quakeFieldNames, ...generalFilters];

export const quakeFilterableOptions: FilterableOption[] = [
    {
        field: 'magnitude',
        label: 'Magnitud',
        type: 'number',
        operations: ['interval', '>', '<', '='],
        step: 0.1,
        min: 0,
    },
    {
        field: 'coordinates',
        label: 'Coordenadas',
        type: 'number',
        operations: ['coordsRange'],
        step: 0.5,
        value: undefined,
    },
    {
        field: 'rms',
        label: 'RMS',
        type: 'number',
        operations: ['interval', '>', '<', '='],
        step: 0.1,
        min: 0,
    },
    {
        field: 'status',
        label: 'Estado',
        type: 'select',
        options: quakeStatusLabelMap,
        operations: ['='],
    },
    {
        field: 'depth',
        label: 'Profundidad',
        type: 'number',
        operations: ['interval', '>', '<', '='],
        step: 1,
        min: 0,
    },
    {
        field: 'datetime',
        label: 'Fecha',
        type: 'date',
        operations: ['interval', '>', '<', '='],
    },
];
