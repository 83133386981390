import dayjs from 'dayjs';

export const formatDate = (date) => {
    return dayjs(date).format('MMM D, YYYY');
};

export const formatTime = (date) => {
    return dayjs(date).format('h:mm:ss a');
};

export const formatCoordinates = (coord1, coord2) => {
    return (
        <>
            <span className='text-gray-400'>(</span>
            {with3Decimals(coord1)}&deg;N
            <span className='text-gray-400'>,</span>
            &nbsp;{with3Decimals(coord2)}&deg;W
            <span className='text-gray-400'>)</span>
        </>
    );
};

export const asInt = (value) => {
    return Number(value).toFixed(0);
};

export const with1Decimal = (value) => {
    return Number(value).toFixed(1);
};

export const with2Decimals = (value) => {
    return Number(value).toFixed(2);
};

export const with3Decimals = (value) => {
    return Number(value).toFixed(3);
};
