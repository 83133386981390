import { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import QuakeMarker from './QuakeMarker';
import { blueSeaMapsTheme } from '../../../../shared/mapsThemes';
import { useSearchParams } from 'react-router-dom';

const GenericMarker = () => {
    return (
        <div className="-ml-1.5 -mt-1.5 h-3 w-3 rounded-full bg-gray-300 "></div>
    );
};

export default function QuakeMap(props) {
    const { center, zoom, items, selectedItem, onSelectedItem } = props;
    const mapApiKey = 'AIzaSyDvODQ6evWYygh_7WNLBYHCHE-J6rTBfIU';
    const [searchParams] = useSearchParams();
    const [coords, setCoords] = useState(null);
    const googleMapsRef = useRef();
    const mapRef = useRef();
    const limitAreaRef = useRef();
    // items should be reversed otherwise will render in the wrong order
    const sortedItems = [...items].reverse();

    const handleActivateMarker = (quake) => {
        onSelectedItem(quake);
    };

    useEffect(() => {
        if (!coords) {
            removePolygon();
        }
    }, [coords]);

    useEffect(() => {
        if ((searchParams, googleMapsRef.current && mapRef.current)) {
            const qsCoords = searchParams.get('coordinates');
            if (!qsCoords) {
                setCoords(null);
                return;
            }
            const [latNorth, latSouth, lngWest, lngEast] = qsCoords.split('|');
            if (!latNorth || !latSouth || !lngWest || !lngEast) {
                setCoords(null);
                return;
            }
            setCoords({
                latNorth: +latNorth,
                latSouth: +latSouth,
                lngWest: +lngWest,
                lngEast: +lngEast,
            });
            const verticeCoords = [
                { lat: +latNorth, lng: +lngEast },
                { lat: +latNorth, lng: +lngWest },
                { lat: +latSouth, lng: +lngWest },
                { lat: +latSouth, lng: +lngEast },
            ];
            addPolygon(verticeCoords, coords);
        }
    }, [searchParams, googleMapsRef.current, mapRef.current]);

    const addPolygon = (verticeCoords, coords) => {
        if (!coords) {
            limitAreaRef.current = null;
        }

        const isNotValid = !isValid();
        const opts = {
            paths: verticeCoords,
            strokeColor: isNotValid ? '#FFFF00' : '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: isNotValid ? '#FFFFFF' : '#FF0000',
            fillOpacity: 0.15,
        };
        if (limitAreaRef.current) {
            limitAreaRef.current.setOptions(opts);
            limitAreaRef.current.setMap(mapRef.current);
        } else {
            limitAreaRef.current = new googleMapsRef.current.Polygon(opts);
            limitAreaRef.current.setMap(mapRef.current);
        }
    };

    const removePolygon = () => {
        limitAreaRef.current?.setMap(null);
    };

    const isValid = () => {
        return (
            coords &&
            coords.latNorth > coords.latSouth &&
            coords.lngWest > coords.lngEast
        );
    };

    const handleGoogleApiLoaded = ({ maps, map }) => {
        googleMapsRef.current = maps;
        mapRef.current = map;
    };

    return (
        <div
            style={{ height: '100%', width: '100%' }}
            className="overflow-hidden rounded-lg"
        >
            <GoogleMapReact
                bootstrapURLKeys={{ key: mapApiKey }}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals={true}
                options={{ styles: blueSeaMapsTheme }}
                onGoogleApiLoaded={handleGoogleApiLoaded}
            >
                {coords && (
                    <GenericMarker lat={coords.latNorth} lng={coords.lngWest} />
                )}
                {coords && (
                    <GenericMarker lat={coords.latSouth} lng={coords.lngEast} />
                )}

                {sortedItems.map((quake) => (
                    <QuakeMarker
                        selected={selectedItem?.id === quake.id}
                        quake={quake}
                        key={quake.id}
                        lat={quake.latitude}
                        lng={quake.longitude}
                        onActivate={handleActivateMarker}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
}
