import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const OrderDirectionIcon = ({ field, orderBy, orderDirection }) => {
    return (
        <div>
            <ChevronUpIcon
                className={`h-4 w-4 ${
                    orderBy === field && orderDirection === 'asc'
                        ? ' opacity-100'
                        : ' opacity-25'
                }`}
            />
            <ChevronDownIcon
                className={`h-4 w-4 -mt-2 ${
                    orderBy === field && orderDirection === 'desc'
                        ? ' opacity-100'
                        : ' opacity-25'
                }`}
            />
        </div>
    );
};

export default OrderDirectionIcon;
