import React from 'react';
import dayjs from 'dayjs';
import { WiEarthquake } from 'react-icons/wi';
import { BsArrowBarUp } from 'react-icons/bs';
import { quakeCategoryOptions, quakeStatusLabelMap } from '../../Panel/modules/quakes/utils/enum';
import { asInt, formatCoordinates, with1Decimal } from '../../shared/format';
import MecFocal from '../../shared/MecFocal';
import { calculateAuxiliaryPlane, hasMecFocal } from '../../shared/utils';

const InfoMarkerComponent = (props) => {
    const {
        description,
        magnitude,
        datetime,
        latitude,
        longitude,
        category,
        depth,
        location,
        rms,
        status,
        unit,
        meca_str: str1,
        meca_dip: dip1,
        meca_rake: rake1,
        meca_ref: mecaRef,
        beachball_url: beachballUrl,
        meca_aux_plane: mecaAuxPlane
    } = props.quakeData;

    return (
        <div
            style={props.style}
            className={props.className + ' flex flex-col items-center'}
        >
            <div className="relative h-0 w-0 border-r-[10px] border-l-[10px] border-b-[16px] border-t-0 border-solid border-white border-l-transparent border-r-transparent"></div>
            <div
                className={
                    'infoMarker w-96 grid-cols-2 rounded-lg bg-white py-6 px-6 shadow-md'
                }
            >
                <div className="grid grid-cols-3 justify-start items-start gap-x-3 gap-y-3 w-full ">
                    <div className="item flex flex-wrap items-center">
                        <div className="flex w-full items-center justify-start text-gray-500 uppercase text-[.6rem]">
                            <WiEarthquake
                                className="text-base text-gray-500"
                                // color={colorQuake}
                            />
                            Magnitud
                        </div>
                        <div className="flex w-full items-center text-center text-3xl font-bold text-brand-blue">
                            {with1Decimal(magnitude)}
                            <sub className="text-base">{unit}</sub>
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center">
                        <div className="flex w-full items-center justify-start text-gray-500 uppercase text-[.6rem]">
                            <BsArrowBarUp
                                className="text-base text-gray-500"
                                // color={colorQuake}
                            />
                            Profundidad
                        </div>
                        <div className="flex w-full items-center text-center text-3xl font-bold text-brand-brown">
                            {asInt(depth)}
                            <sub className="text-base">KMs</sub>
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center">
                        <div className="flex w-full items-center text-gray-500 uppercase text-[.6rem]">
                            RMS
                        </div>
                        <div className="flex w-full items-center text-3xl font-bold text-brand-brown">
                            {with1Decimal(rms)}
                        </div>
                    </div>

                    <div className="-pt-4 -pb-4 col-span-3 border-gray-100 border-b-2 border-dashed"></div>

                    <div className="item flex flex-wrap items-center justify-center">
                        <div className="flex w-full items-center justify-start text-gray-500 uppercase text-[.6rem]">
                            Fecha (Local)
                        </div>
                        <div className="w-full text-left text-[0.9rem] leading-1 text-gray-700">
                            {dayjs(datetime).format('h:mm A')}
                        </div>
                        <div className="w-full text-left text-[0.9rem] leading-1 text-gray-700">
                            {dayjs(datetime).format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center">
                        <div className="flex w-full items-center justify-start text-gray-500 uppercase text-[.6rem]">
                            Estado
                        </div>
                        <div className="w-full text-left text-[0.9rem] text-gray-600">
                            {quakeStatusLabelMap[status]}
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center">
                        <div className="flex w-full items-center justify-start text-gray-500 uppercase text-[.6rem]">
                            Lugar
                        </div>
                        <div className="w-full text-left text-[0.9rem] text-gray-600">
                            {location || description || 'Desconocido'}
                        </div>
                    </div>
                    <div className="item flex col-span-2 flex-wrap items-center justify-center">
                        <div className="flex w-full items-center justify-start text-gray-500 uppercase text-[.6rem]">
                            Coordenadas
                        </div>
                        <div className="w-full text-left text-base text-gray-600">
                            {formatCoordinates(latitude, longitude)}
                        </div>
                    </div>
                    <div className="item flex flex-wrap items-center justify-center">
                        <div className="flex w-full items-center justify-start text-gray-500 uppercase text-[.6rem]">
                            Categoría
                        </div>
                        <div className="w-full text-left text-base text-gray-600">
                            {quakeCategoryOptions.find(
                                (c) => c.value === category.toUpperCase()
                            )?.label || ''}
                        </div>
                    </div>

                    {hasMecFocal(props.quakeData) ?
                        <>
                            <div
                                className="col-span-3 flex flex-col space-y-2  border-gray-100 border-t-2 border-dashed pt-4">
                                <div
                                    className="font-bold text-[.7rem] font-medium text-gray-700 uppercase">Mecanismo
                                    Focal
                                </div>
                                <div className="grid-cols-6 grid -mb-3 -mt-3">
                                    <MecFocal value={str1} label="Str1" marginClass="mt-0" />
                                    <MecFocal value={dip1} label="Dip1" marginClass="mt-0" />
                                    <MecFocal value={rake1} label="Rake1" marginClass="mt-0" />
                                    <MecFocal value={mecaAuxPlane?.str} label="Str2" marginClass="mt-0" />
                                    <MecFocal value={mecaAuxPlane?.dip} label="Dip2" marginClass="mt-0" />
                                    <MecFocal value={mecaAuxPlane?.rake} label="Rake2" marginClass="mt-0" />
                                </div>
                            </div>
                            <div className="flex col-span-3 justify-center">
                                <img
                                    className={`w-40 h-40 `}
                                    src={beachballUrl}
                                />
                            </div>
                        </> : null
                    }
                </div>
                <button
                    title="Cerrar Cuadro de Información"
                    onClick={props.onClose}
                    className="infoMarker-close absolute right-2 top-4 cursor-pointer rounded-full mr-1 mt-3  px-[0.6rem] py-1 text-sm hover:font-bold text-brand-blue hover:opacity-75 hover:bg-gray-300 hover:rounded-full"
                >
                    X
                </button>
            </div>
        </div>
    );
};

export default InfoMarkerComponent;
