import React from 'react';

const TimeField = (props) => {
    const { value: time, onChange } = props;
    const handleChange = (e) => {
        let tm = e.target.value;
        if (tm.length === time.length - 1 && time[time.length - 1] === ':') {
            tm = tm.substring(0, tm.length - 1);
        }

        if (!/^[0-9:]{0,8}$/.test(tm)) return;


        const splits = tm.split(':');

        if (splits.length > 3) return;

        let h = splits?.[0] || '';
        let m = splits?.[1] || '';
        let s = splits?.[2] || '';

        if (h.length === 0 || h.length > 2 || +h >= 24) {
            onChange('');
            return;
        } else if (h.length < 2) {
            onChange(h);
            return;
        } else {
            h = h + ':';
        }

        if (m !== '' && (m.length === 0 || m.length > 2 || +m >= 60)) {
            onChange(h);
            return;
        } else if (m.length < 2) {
            onChange(h + m);
            return;
        } else {
            m = m + ':';
        }

        if (s !== '' && (s.length === 0 || s.length > 2 || +s >= 60)) {
            onChange(h + m);
            return;
        }

        onChange(h + m + s);
    };

    return (
        <input
            {...props}
            type="text"
            value={time}
            onChange={handleChange}
        />
    );

};

export default TimeField;
