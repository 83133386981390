import { atom, selector } from 'recoil';
import { USER_ROLES } from '../utils/enums';

const posts = [];
export const quakesAtom = atom({
    key: 'quakesAtom',
    default: {}
});

export const userAtom = atom({
    key: 'userAtom',
    default: null
});

export const currentMarkerAtom = atom({
    key: 'currentMarkerAtom',
    default: null
});

export const pageListState = atom({
    key: 'pagesState',
    default: {
        data: []
    }
});

export const postListAtom = atom({
    key: 'postsListAtom',
    default: {
        data: []
    }
});

export const userRoleAtom = atom({
    key: 'userRole',
    default: USER_ROLES.GUEST
});

export const userRoleFlagSelector = selector({
    key: "userRoleFlagSelector",
    get: ({ get }) => {
        const userRole = get(userRoleAtom);
        return {
            isAdmin: userRole === USER_ROLES.ADMIN,
            isEditor: userRole === USER_ROLES.EDITOR,
            isGuest: userRole === USER_ROLES.GUEST,
            isUser: userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.EDITOR
        };
    }
});
