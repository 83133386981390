import { apiClient } from '../../utils/httpClients';

export function fetchQuakes(qString) {
    return apiClient
        .get(`quakes?${qString}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => console.error(err));
}

export function updateQuake(data) {
    return apiClient.patch('quakes/' + data.id, data).then((res) => {
        return res.data;
    });
}

export function createQuake(data) {
    return apiClient.post('quakes', data).then((res) => {
        return res.data;
    });
}

export function deleteQuake(quakeId) {
    return apiClient.delete('quakes/' + quakeId).then((res) => {
        return res.data;
    });
}

export function downloadAsCsv(params) {
    const search = new URLSearchParams(window.location.search)
    search.set('format', 'csv')
    const pathParams = '/api/quakes?' + search.toString();
    const downloadUUrl = import.meta.env.VITE_BASEURL + pathParams;
    window.open(downloadUUrl);
}
