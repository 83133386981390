import { XCircleIcon } from '@heroicons/react/solid';

const ValidationError = ({ errors = [], className }) => {
    return !!errors?.length ? (
        <div
            className={` ${className} rounded-xs bg-red-300 p-3 text-gray-600`}
        >
            {errors.map((error, i) => (
                <div key={i} className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-3">
                        <span className="text-sm font-medium text-red-800">
                            {error}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    ) : (
        ''
    );
};

export default ValidationError;
