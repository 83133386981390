export const quakeStatusLabelMap = {
    automatic: 'Automático',
    provisional: 'Provisional',
    revised: 'Revisado',
};

export enum QuakeStatus {
    AUTOMATIC = 'automatic',
    PROVISIONAL= 'provisional',
    REVISED = 'revised',
}

export const quakeCategoryOptions = [
    {
        label: 'Local',
        value: 'L'
    },
    {
        label: 'Regional',
        value: 'R'
    },
    {
        label: 'Distante',
        value: 'D'
    }
];

export const quakeCategorysMap = {
    'L': quakeCategoryOptions[0].label,
    'R': quakeCategoryOptions[1].label,
    'D': quakeCategoryOptions[2].label,
};

export const DEFAULT_QUAKE_STATUS = QuakeStatus.PROVISIONAL;
export const DEFAULT_QUAKE_CATEGORY = quakeCategoryOptions[0].value;
