import { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@heroicons/react/solid';
import SearchField from '../../../components/SearchField';
import { FilterTopActions } from '../../../components/Filter/FilterTopActions';
import QuakeFilter from '../../../components/Filter';
import { getQuakesFilters, quakeFieldNames, quakeFilterableOptions } from '../utils/filter';

export default function QuakeToolBar({ meta = {}, links = {}, search, reset }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState({});
    const [showSearch, setShowSearch] = useState(false);
    const [showAddEditor, setShowAddFilter] = useState(false);

    // useEffect(() => {
    //     if (!searchParams) return;
    //     const filters = getFiltersFromQueryString(searchParams, quakeFieldNames);
    //     setFilters({
    //         ...filters
    //     });
    // }, [searchParams]);

    const getRelativeUrl = (linkUrl) => {
        if (!linkUrl) return '#';
        const url = new URL(window.location.toString());

        const page = new URL(linkUrl).searchParams.get('page');
        if (page) url.searchParams.set('page', page);

        const limit = new URL(linkUrl).searchParams.get('limit');
        if (limit) url.searchParams.set('limit', limit);

        const search = new URL(linkUrl).searchParams.get('search');
        if (search) url.searchParams.set('search', search);

        return `?${url.searchParams.toString()}`;
    };

    const handleSearch = (text) => {
        const params = getQuakesFilters(searchParams);
        if (text.trim()) {
            params.search = text.trim();
        } else {
            delete params.search; // reset search
        }
        delete params.page; // reset page
        setSearchParams(params);
    };

    return (
        <div className='flex flex-col space-y-2'>
            <div className='flex items-center justify-between'>
                <div className='flex w-full justify-between sm:hidden'>
                    <section className='flex flex-col space-y-0'>
                        <p className='lg:hidden text-xs text-gray-500 font-medium'>
                            {meta.total > 0 ?
                                <>
                                    <span>{meta.from || 0} - {meta.to}</span> de{' '}
                                    <span>{meta.total || 0} resultados</span>
                                </> :
                                '0 resultados'
                            }
                        </p>
                        <div className='flex space-x-3'>
                            {links.prev &&
                                <NavLink
                                    to={getRelativeUrl(links.prev)}
                                    className='relative inline-flex items-center rounded-md text-sm font-medium text-brand-blue '
                                >
                                    <ChevronLeftIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    <span className=''>Anterior</span>
                                </NavLink>
                            }
                            {links.next &&
                                <NavLink
                                    to={getRelativeUrl(links.next)}
                                    className='relative inline-flex items-center rounded-md text-sm font-medium text-brand-blue'
                                >
                                    <span className=''>Siguiente</span>
                                    <ChevronRightIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                </NavLink>
                            }
                        </div>
                    </section>
                    <FilterTopActions
                        filters={filters}
                        publicPath='/archivo-de-sismos'
                        privatePath='/panel/quakes'
                        withCsvExport={true}
                        onOpenEditor={() => setShowAddFilter(true)}
                        onCloseEditor={() => setShowAddFilter(false)}
                    />
                </div>

                <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
                    <div className='flex flex-col space-y-1'>
                        <p className='text-sm text-gray-700'>
                            Mostrando{' '}
                            <span className='font-medium'>del {meta.from || 0}</span> al{' '}
                            <span className='font-medium'>{meta.to || 0}</span> de{' '}
                            <span className='font-medium'>{meta.total || 0}</span>{' '}
                            resultados
                        </p>
                        <FilterTopActions
                            filters={filters}
                            publicPath='/archivo-de-sismos'
                            privatePath='/panel/quakes'
                            withCsvExport={true}
                            onOpenEditor={() => setShowAddFilter(true)}
                            onCloseEditor={() => setShowAddFilter(false)}
                        />
                    </div>
                    <div className='flex flex-1 space-x-4 justify-end'>
                        <button
                            type='button'
                            onClick={() => setShowSearch(!showSearch)}
                            className={`
                                text-sm text-brand-blue group items-center hover:cursor-pointer px-2 rounded-full
                                ${showSearch ? 'text-gray-100 bg-brand-blue' : 'hover:bg-gray-300 text-gray-400 bg-gray-200'}
                            `}
                        >
                            <SearchIcon className='w-6 h-6'></SearchIcon>
                        </button>
                        <nav
                            className='relative z-0 inline-flex -space-x-px rounded-md shadow-sm'
                            aria-label='Pagination'
                        >
                            {meta.links?.map((link, i) => {
                                if (i === 0) {
                                    return (link.url &&
                                        <NavLink
                                            key={i}
                                            to={getRelativeUrl(link.url)}
                                            alt='Pagina anterior'
                                            className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50'
                                        >
                                        <span className='sr-only'>
                                            Anterior
                                        </span>
                                            <ChevronLeftIcon
                                                className='h-5 w-5'
                                                aria-hidden='true'
                                            />
                                        </NavLink>
                                    );
                                } else if (i === meta.links.length - 1) {
                                    return (link.url &&
                                        <NavLink
                                            key={i}
                                            to={getRelativeUrl(link.url)}
                                            alt='Pagina siguiente'
                                            className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50'
                                        >
                                        <span className='sr-only'>
                                            Siguiente
                                        </span>
                                            <ChevronRightIcon
                                                className='h-5 w-5'
                                                aria-hidden='true'
                                            />
                                        </NavLink>
                                    );
                                } else if (!link.url) {
                                    return (
                                        <div
                                            key={i}
                                            className='relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50'>
                                            ...
                                        </div>
                                    );
                                }
                                return link.active ? (
                                    <NavLink
                                        key={i}
                                        to={getRelativeUrl(link.url)}
                                        aria-current='page'
                                        className='relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600'
                                    >
                                        {link.label}
                                    </NavLink>
                                ) : (
                                    <NavLink
                                        key={i}
                                        to={getRelativeUrl(link.url)}
                                        className='relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50'
                                    >
                                        {`${link.label}`}
                                    </NavLink>
                                );
                            })}
                        </nav>
                        {reset}
                    </div>
                </div>
            </div>

            <QuakeFilter isOpen={showAddEditor} onClose={() => setShowAddFilter(false)} />

            {showSearch &&
                <SearchField
                    className='relative rounded-md shadow-sm'
                    placeholder='Descripción o lugar'
                    onSearch={handleSearch}
                    debounceTime={400}
                    value={search}
                    autoFocus={true}
                />
            }
        </div>
    );
}
