import {
    FilterableOption,
    FilterDataValue,
    Operators,
    translations,
} from '../../utils/filters';
import React, { FunctionComponent } from 'react';
import CoordinatesRangeEdit from '../FormControls/CoordinatesRangeEdit';
import Input from '../FormControls/Input';
import { MinMaxValue } from '../FormControls/MinMaxValue';
import DateControl from '../FormControls/DateControl';

export interface FormFieldProps {
    value: FilterDataValue;
    options: FilterableOption;
    onChange: (value: FilterDataValue) => void;
    operator: string;
}

export const FormField: FunctionComponent<FormFieldProps> = (props) => {
    const { value, operator, options, onChange } = props;

    if (operator === Operators.COORDS_RANGE) {
        return (
            <CoordinatesRangeEdit
                onChange={onChange}
                value={value}
                step={options.step}
            />
        );
    }

    if (options.type === 'select') {
        return (
            <select
                className="w-64 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                id="value"
                name="value"
                value={value as string}
                onChange={(e) => onChange(e.target.value)}
            >
                <option value="">Seleccione {options.field}</option>
                {Object.keys(options.options).map((key) => (
                    <option value={key} key={key}>
                        {translations[key] || key}
                    </option>
                ))}
            </select>
        );
    } else if (options.type === 'number' && operator !== Operators.INTERVAL) {
        return (
            <div>
                <Input
                    id={options.field + '_filter_input'}
                    value={value}
                    type={options.type}
                    step={options.step || 1}
                    min={options.min || 0}
                    max={options.max}
                    onChange={(e) => onChange(e.target.value)}
                />
            </div>
        );
    } else if (options.type === 'number' && operator == Operators.INTERVAL) {
        return (
            <MinMaxValue
                onChange={onChange}
                filterable={options}
                value={value}
            />
        );
    } else if (options.type === 'date') {
        return (
            <DateControl
                isRange={operator == Operators.INTERVAL}
                id={options.field + '_filter_input'}
                type={options.type}
                value={value}
                onChange={(e) => onChange(e)}
            />
        );
    }
    return <></>;
};
