import React, { useEffect, useState } from 'react';
import {
    FilterableOption,
    FilterData,
    FilterDataValue,
    getDefaultValueForOperator,
    translations,
} from '../../utils/filters';
import { TrashIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';
import { FormField } from './FormField';

export interface FilterEditorProps {
    filterParam?: FilterData;
    onClose: Function;
    onChange: (fd: FilterData) => void;
    onDelete: Function;
    options: FilterableOption;
}

export const FilterEdit = (props: FilterEditorProps) => {
    const { options, filterParam, onChange, onClose, onDelete } = props;
    const [changedOperator, setChangedOperator] = useState('');
    const [changedValue, setChangedValue] = useState<FilterDataValue>('');

    const handleDelete = () => {
        onDelete && onDelete();
    };

    const handleClose = () => {
        onClose && onClose();
    };

    const handleOperatorChange = (e) => {
        const newOp = e.target.value;
        onChange({
            operator: newOp,
            value:
                newOp !== filterParam.operator
                    ? getDefaultValueForOperator(newOp)
                    : changedValue,
        });
    };

    const handleValueChange = (newValue: FilterDataValue) => {
        onChange({
            value: newValue,
            operator: changedOperator,
        });
    };

    useEffect(() => {
        if (!filterParam) return;
        setChangedOperator(filterParam.operator);
        setChangedValue(filterParam.value);
    }, [filterParam]);

    return (
        <div className="FormFilter flex flex-col space-y-4 text-gray-600">
            <div className="flex flex-col space-y-3 xl:space-y-4">
                <label
                    className="text-sm uppercase text-brand-blue"
                    htmlFor={options.field}
                >
                    {options.label}:
                </label>

                <div className="flex w-48 flex-col justify-start space-y-1">
                    <label
                        className="text-xs uppercase text-gray-600"
                        htmlFor="operator"
                    >
                        Operador:
                    </label>
                    {options?.operations?.length >= 1 && (
                        <select
                            className="rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            id="operator"
                            name="operator"
                            value={changedOperator}
                            onChange={handleOperatorChange}
                        >
                            <option value="" disabled={true}>
                                Seleccione operador
                            </option>
                            {(options.operations || []).map((op) => (
                                <option key={op} value={op}>
                                    {translations[op] || op}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="flex w-48 flex-col justify-start space-y-1">
                    {changedOperator !== 'coordsRange' && (
                        <label
                            className="text-xs uppercase text-gray-600"
                            htmlFor="value"
                        >
                            Valor:
                        </label>
                    )}
                    {changedOperator && (
                        <FormField
                            value={changedValue}
                            isRange={changedOperator === 'interval'}
                            onChange={handleValueChange}
                            options={options}
                            operator={changedOperator}
                        />
                    )}
                </div>
            </div>

            <hr className="border-1 border-top border-gray-300" />

            <div className="mt-6 flex space-x-2">
                <button
                    className="inline-flex justify-center space-x-1 rounded-md border border-transparent bg-indigo-600 py-1 px-2 text-xs font-medium capitalize text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    type="button"
                    onClick={handleDelete}
                >
                    <TrashIcon className="h-4 w-4" />
                    <span>Eliminar Filtro</span>
                </button>
                <button
                    className="flex items-center space-x-1 text-xs capitalize"
                    type="button"
                    onClick={handleClose}
                >
                    <XIcon className="h-4 w-4" />
                    <span>Cerrar Editor</span>
                </button>
            </div>
        </div>
    );
};

export default FilterEdit;
