import React from 'react';

 function Input(props) {
    return (
        <input
            {...props}
            name={props.id}
            type={props.type || "text"}
            className={
                props.className +
                ' block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
            }
        />
    );
}

export default Input
