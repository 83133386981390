import { SearchIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SearchField = (props) => {
    const {
        debounceTime,
        onSearch,
        value,
        autocomplete,
        placeholder,
        autoFocus
    } = props;
    const [searchText, setSearchText] = useState(value || '');

    const handleDebouncedSearch = () => {
        onSearch && onSearch(searchText);
    };

    const handleSearchTextChange = (e) => {
        // if(searchText === e.target.value) return Delete me if you dont remember
        setSearchText(e.target.value);
    };

    useEffect(() => {
        const timer = setTimeout(() => handleDebouncedSearch(), debounceTime);
        return () => clearTimeout(timer);
    }, [searchText]);


    return (
        <div className={props.className}>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <SearchIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </div>
            <input
                autoFocus={autoFocus}
                placeholder={placeholder}
                autoComplete={autocomplete}
                type='text'
                name='search'
                id='search'
                className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md'
                value={searchText}
                onChange={handleSearchTextChange}
            />
        </div>
    );
};

SearchField.defaultValues = {
    debounceTime: 400,
    autocomplete: 'off',
    placeholder: ''
};

SearchField.propTypes = {
    autocomplete: PropTypes.oneOf(['on', 'off']),
    placeholder: PropTypes.string,
    debounceTime: PropTypes.number,
    onSearch: PropTypes.func
};

export default SearchField;
