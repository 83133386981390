export function getUrl(path, withCurrentParams = false) {
    const defaultUrl = import.meta.env.VITE_SITEURL + path;
    return defaultUrl + (withCurrentParams ? window.location.search: '' )
}

export function isNum (value) {
    return !isNaN(value) && value !== null && value !== ""
};

export function hasMecFocal(quake) {
    const { meca_str, meca_dip, meca_rake } = quake;
    return isNum(meca_str) || isNum(meca_dip) || isNum(meca_rake);
}

export function calculateAuxiliaryPlane(str1, dip1, rake1, callback) {
    // debugger
    let str2, dip2, rake2;

    if (dip1 === 90) {
        // Vertical fault plane
        str2 = str1 + 90;
        if (str2 > 360) {
            str2 -= 360;
        }
        dip2 = 90;
        rake2 = -rake1;
    } else if (dip1 === 0) {
        // Horizontal fault plane
        str2 = str1 + 90;
        if (str2 > 360) {
            str2 -= 360;
        }
        dip2 = 0;
        rake2 = -rake1;
    } else {
        // Regular fault plane
        str2 = computedStrike1(str1, dip1, rake1);
        dip2 = computedDip1(str1, dip1, rake1);

        // Calculate rake2
        if (rake1 >= 0 && rake1 <= 180) {
            rake2 = 180 - rake1;
        } else {
            rake2 = -180 - rake1;
        }
    }
    return callback(str2, dip2, rake2);
}

function computedStrike1(strike1, dip1, rake1) {
    let strike = strike1 + 180;
    if (strike > 360) {
        strike -= 360;
    }
    return strike;
}

function computedDip1(strike1, dip1, rake1) {
    return 90 - dip1;
}
