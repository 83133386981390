import React, { FC } from 'react';
import { FilterDetails } from './FilterDetails';
import FilterEdit from './FilterEdit';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    getObjectValidFilters,
    quakeFilterableOptions,
    setQsFilter,
    removeQsFilter,
} from '../../modules/quakes/utils/filter';
import { FilterData, FilterDataMap } from '../../utils/filters';
import Modal from '../Modal';
import FilterAdd from './FilterAdd';

export interface QuakeFilter {
    isOpen: boolean;
    onClose: () => void;
}

const QuakeFilter = (props: QuakeFilter) => {
    const { isOpen, onClose } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [editingField, setEditingField] = useState('');
    const [filters, setFilters] = useState<FilterDataMap>({});

    const setEditingMode = (field) => {
        setEditingField(field && field !== editingField ? field : '');
    };

    const handleFilterDelete = () => {
        setEditingMode('');
        const qString = removeQsFilter(editingField, searchParams.toString());
        setSearchParams(qString);
    };

    const handleFilterChange = (fd: FilterData) => {
        if (
            Array.isArray(fd.value) &&
            fd.value.filter((f) => f !== '').length === 0
        ) {
            return;
        }
        const qString = setQsFilter(
            { ...fd, field: editingField },
            searchParams.toString()
        );
        setSearchParams(qString);
    };

    const handleNewApply = (filterData: FilterData) => {
        const qString = setQsFilter(filterData, searchParams.toString());
        setSearchParams(qString);
        onClose && onClose();
    };

    useEffect(() => {
        if (!searchParams) return;
        setFilters(getObjectValidFilters(searchParams.toString()));
    }, [searchParams]);

    return (
        <>
            <Modal open={isOpen} closeModal={onClose}>
                <FilterAdd
                    onApply={handleNewApply}
                    onClose={() => onClose && onClose()}
                />
            </Modal>

            {Object.keys(filters).length > 0 && (
                <div className="quakes-filter rounded-lg bg-slate-200 p-3">
                    <FilterDetails
                        filters={filters}
                        filterableOptions={quakeFilterableOptions}
                        onEditClicked={setEditingMode}
                        editingField={editingField}
                    />
                </div>
            )}

            {editingField && (
                <div className="quakes-filter rounded-lg bg-slate-200 p-3">
                    <FilterEdit
                        filterParam={filters[editingField]}
                        options={quakeFilterableOptions.find(
                            (f) => f.field === editingField
                        )}
                        onClose={setEditingMode}
                        onChange={handleFilterChange}
                        onDelete={handleFilterDelete}
                    />
                </div>
            )}
        </>
    );
};

export default QuakeFilter;
